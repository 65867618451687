import { Fragment, lazy, Suspense, useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";

import yaml from 'js-yaml'
import { jwtDecode } from "jwt-decode";


import validate from 'tools/validate'
import { userContext, userSchema } from 'tools/userContext';
import { gameContext } from 'tools/gameContext'
import { newClient } from 'tools/backendContext'
import {setSession} from 'tools/localStorage'

import Loading from 'routes/loading/loadingPage'

// import RTL from 'tools/rtl'
// import {ThemeProvider, createTheme} from '@mui/material'
import { Route, Routes } from "react-router-dom";


const GamePage = lazy(() => import('routes/game/gamePage'))
const LeaderBoardPage = lazy(() => import('routes/leaderboard/leaderBoardPage'))
const GameListPage = lazy(() => import("routes/gameList/gameListPage"))
const MainPage = lazy(() => import("routes/main/mainPage"))
const ErrorPage = lazy(() => import("routes/error/errorPage"))


function App() {

    const [searchParams] = useSearchParams();
    const [user, setUser] = useState({})
    const [games, setGames] = useState({})
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    const [smallTilesList, setSmallTilesList] = useState([])
    const [mediumTilesList, setMediumTilesList] = useState([])
    const [bigTilesList, setBigTilesList] = useState([])
    const titles = [];

    useEffect(() => {

        const  backendInitializr = async (user) => {

            var client = newClient()
            try {
                const session = await client.authenticateCustom(user.token, true, user.username)
                setSession(session)
            } catch(err){
                console.log(err)
                setError(true)
            }
            
        }

        if(!searchParams.has('token')){
            console.log("token not provided")
            setError(true)
            setLoading(false)
            return
        }



        let u = {
            token: searchParams.get("token"),
            username: "gamer"
        }

        try {
            const decoded = jwtDecode(u.token);
            if(typeof decoded.username == 'undefined'){
                console.log("username not provided as a token claim")
                setError(true)
                setLoading(false)
                return    
            }

            u.username = decoded.username

        }catch(e){
            console.log(e)
            setError(true)
            setLoading(false)
            return
        }


        const errors = validate(u, userSchema)

        if (errors.length > 0) {
            console.log(errors)
            setError(true)
            setLoading(false)
            return
        } else {
            setUser(u)
        }

        fetch(process.env.REACT_APP_STORAGE_URL + process.env.REACT_APP_CONFIG_BUCKET + "/config-liom.yml", {})
            .then(res => res.blob())
            .then(blob => blob.text())
            .then(s => {
                try {
                    let config = yaml.load(s)
                    setGames(config.games)
                    setSmallTilesList(config.small_tiles)
                    setMediumTilesList(config.medium_tiles)
                    setBigTilesList(config.big_tiles)
                    setLoading(false)
                }catch(e){
                    console.log(e)
                    setError(true)
                    setLoading(false)
                }
                
            }).catch(err => {
                console.log(err)
                setError(true)
                setLoading(false)
            })

            
            backendInitializr(u)

    }, [searchParams])

    titles.push(smallTilesList,mediumTilesList,bigTilesList)


    // const theme = createTheme({
    //     typography: {
    //         fontFamily: 'Roboto, B Badr',
    //     }
    // });

    if (error) {
        return <ErrorPage />
    }

    if (loading) {
        return <Loading />
    }

    return (
        // <ThemeProvider theme={theme}>
        <Fragment>
            <userContext.Provider value={user}>
                <gameContext.Provider value={games}>
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route path="/" element={<MainPage titles={titles}/>} />
                            <Route path="/game/:game_id/:leaderboard_id" element={<GamePage />} />
                            <Route path="/board" element={<GameListPage />} />
                            <Route path="/board/:game_id" element={<LeaderBoardPage />} />
                            <Route
                                path="*"
                                element={
                                    <main style={{ padding: "1rem" }}>
                                        <p>There's nothing here!</p>
                                    </main>
                                }
                            />
                        </Routes>
                    </Suspense>
                </gameContext.Provider>
            </userContext.Provider>
        </Fragment>
        // </ThemeProvider>
    );
}

export default App;
